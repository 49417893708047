import {EditorSDK} from '@wix/platform-editor-sdk'
import {ISettingsContextValue} from '@wix/tpa-settings'
import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {Experiments, IHttpClient, IWixStatic, OwnerLogger, TFunction} from '@wix/yoshi-flow-editor'
import {Api} from './api'

export type Extra = {
  serverApi: Api
  Wix: IWixStatic
  editorSDK: EditorSDK
  environment: {
    language: string
    isEditorX: boolean
    isStudio: boolean
    msid: string
  }
  bi: OwnerLogger
  settings: ISettingsContextValue
  experiments: Experiments
  httpClient: IHttpClient
  t: TFunction
  siteLanguageT: TFunction
}

export const createAsyncAction = asyncActionsFactory<any, Extra>()
