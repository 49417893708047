import {FilterFormData} from '../components/modals/filters-modal/interfaces'
import {createAsyncAction} from '../services/redux-toolkit'
import {getSchedule} from './schedule'

export const resetFilter = createAsyncAction('RESET_FILTER', (_, {dispatch}) => dispatch(getSchedule()))

export const setFilter = createAsyncAction<any, {data: FilterFormData}>('SET_FILTER', (_, {dispatch}) =>
  dispatch(getSchedule()),
)

export const setLocation = createAsyncAction<any, string>('SET_LOCATION', (_, {dispatch}) => dispatch(getSchedule()))

export const toggleTag = createAsyncAction<any, string>('TOGGLE_TAG', (_, {dispatch}) => dispatch(getSchedule()))

export type SetLocation = typeof setLocation
export type ToggleTag = typeof toggleTag
export type ResetFilter = typeof resetFilter
export type SetFilter = typeof setFilter
