import {createReducer} from '@reduxjs/toolkit'
import {ScheduleItem} from '@wix/ambassador-events-schedule-v1-schedule-item/types'
import {ExtendedListScheduleItemsResponse} from '@wix/wix-events-commons-statics'
import {createBookmark, deleteBookmark, getBookmarks, getSchedule, getScheduleData} from '../actions/schedule'
import {ScheduleItemResponse} from '../types'

const initialState: ScheduleState = {
  items: [],
  allItems: [],
  bookmarkedItems: [],
  total: 0,
  availableLocations: [],
  availableTags: [],
  bookmarks: [],
  bookmarking: false,
}

export const schedule = createReducer(initialState, builder => {
  builder
    .addCase(getSchedule.fulfilled, (state, action) => updateStateWithSchedule(state, action.payload))
    .addCase(getScheduleData.fulfilled, (state, action) =>
      updateStateWithSchedule(state, action.payload.schedule ?? {items: [], facets: {}}, true),
    )
    .addCase(getBookmarks.fulfilled, (state, action) => {
      const bookmarks = action.payload.items

      state.bookmarks = bookmarks
      state.items = state.items.map(item => {
        item.bookmarked = bookmarks.some(bookmark => bookmark.id === item.id)
        return item
      })
      state.bookmarkedItems = state.allItems
        .filter(item => bookmarks.some(bookmark => bookmark.id === item.id))
        .map(item => {
          item.bookmarked = true
          return item
        })
    })
    .addCase(createBookmark.pending, state => {
      state.bookmarking = true
    })
    .addCase(createBookmark.fulfilled, (state, action) => {
      const {itemId} = action.meta.arg
      const newBookmark = state.items.find(item => item.id === itemId)

      state.bookmarking = false
      state.bookmarks = [...state.bookmarks, newBookmark]
      state.items.forEach(item => {
        if (item.id === itemId) {
          item.bookmarked = true
        }
      })
      state.bookmarkedItems = [...state.bookmarkedItems, {...newBookmark, bookmarked: true}]

      return state
    })
    .addCase(createBookmark.rejected, state => {
      state.bookmarking = false
    })
    .addCase(deleteBookmark.pending, state => {
      state.bookmarking = true
    })
    .addCase(deleteBookmark.fulfilled, (state, action) => {
      const {itemId} = action.meta.arg

      state.bookmarking = false
      state.bookmarks = state.bookmarks.filter(bookmark => bookmark.id !== itemId)
      state.items.forEach(item => {
        if (item.id === itemId) {
          item.bookmarked = false
        }
      })
      state.bookmarkedItems = state.bookmarkedItems.filter(item => item.id !== itemId)

      return state
    })
    .addCase(deleteBookmark.rejected, state => {
      state.bookmarking = false
    })
})

const updateStateWithSchedule = (
  state: ScheduleState,
  {items, total, facets}: ExtendedListScheduleItemsResponse,
  setAllItems = false,
) => {
  if (!state.total) {
    state.total = total
  }

  if (!state.availableLocations.length && !state.availableTags.length) {
    state.availableLocations = Object.keys(facets.stageName?.counts ?? {})
    state.availableTags = Object.keys(facets.tag?.counts ?? {})
  }

  state.items = items.map(item => ({...item, bookmarked: state.bookmarks.some(bookmark => bookmark.id === item.id)}))

  if (setAllItems) {
    state.allItems = items
  }
}

export interface ScheduleState {
  items: ScheduleItemResponse[]
  allItems: ScheduleItemResponse[]
  bookmarkedItems: ScheduleItemResponse[]
  total: number
  availableLocations: string[]
  availableTags: string[]
  bookmarks: ScheduleItem[]
  bookmarking: boolean
}
