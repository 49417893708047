import {ALL_LOCATIONS} from '../reducers/filter'
import {ScheduleState} from '../reducers/schedule'
import {ScheduleDays, ScheduleItem} from '../types'

export const getDays = ({schedule, bookmarkedOnly = false}: GetDaysParams): ScheduleDays => {
  const items = bookmarkedOnly ? schedule.bookmarkedItems : schedule.items

  return items.reduce((acc, item) => {
    const scheduleItem: ScheduleItem = {
      formattedTimeSlot: item.formattedTime,
      status: item.status,
      formattedUpdatedDate: item.formattedUpdatedDate,
      formattedDuration: item.formattedDuration,
      name: item.name,
      id: item.id,
      description: item.description,
      tags: item.tags?.map(tag => ({
        checked: false,
        title: tag,
        value: tag,
      })),
      location: item.stageName,
      bookmarked: item.bookmarked,
    }
    acc[item.key] = {
      title: item.shortDate,
      items: [...(acc[item.key]?.items ?? []), scheduleItem],
    }

    return acc
  }, {} as ScheduleDays)
}

export interface GetDaysParams {
  schedule: ScheduleState
  bookmarkedOnly?: boolean
}

export interface ScheduleTag {
  checked: boolean
  title: string
  value: string
}

export const getTags = ({availableTags}: ScheduleState, selectedTags: string[]): ScheduleTag[] =>
  availableTags.map(value => ({
    value,
    title: value,
    checked: selectedTags.includes(value),
  }))

export interface ScheduleLocation {
  id: string
  value: string
  isDisabled: boolean
  isSelectable: boolean
}

export const getLocations = ({availableLocations}: ScheduleState, t: Function): ScheduleLocation[] => {
  const allLocations = {
    id: ALL_LOCATIONS,
    value: t('schedulePlaces.all'),
    isDisabled: false,
    isSelectable: true,
  }

  return [
    allLocations,
    ...availableLocations
      .filter(location => Boolean(location))
      .map(value => ({id: value, value, isDisabled: false, isSelectable: true})),
  ]
}
